<template>
    <div class="row patient_chart">
        <sidebar></sidebar>
        <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 appointment_history allergies">
            <div class="a_header">
                <div>
                    <div class="search">
                        <img class="serch_icon" src="/images/search-icon.svg" alt="">
                        <input type="text" @keyup="patientLabSearch" class="form-control"
                            placeholder="Search Labs" v-model="lab_search" @blur="patientLabSearch"
                            ref="globalSearch">
                    </div>
                    <span class="position-relative">
                        <button class="comman_btn" @click="addFilter" v-if="this.filterList.length == 0"> Add Filter
                        </button>
                        <button class="comman_btn" @click="addFilter" v-else>Filters | {{ this.filterList.length }}</button>
                        <CustomFilter :options="this.filterOptions" v-if="isShowCustomFilter"
                            @custom-filter="handleCustomField" :selectedOpt="this.filterList" @refresh-data="getAllLabList"></CustomFilter>
                        <button class="comman_brdr_btn consexport-btn"  :disabled="this.selectedItems.length == 0"  v-if="this.$route.name !== 'PatientEChartFilesDelete'" @click="exportconsults()"> Export </button>

                    </span>
                </div>
                <div>
                    <button class="comman_btn" v-if="$route.params.patient_id" @click="selectTemplate"> Select Template </button>
                </div>
            </div>
            <div class="table-responsive consult-list labs-overview-table">
                <table class="table dropdown_conain_data">
                    <thead>
                        <tr>
                            <th class="hover_unset w-50px"><input class="form-check-input" type="checkbox"
                                    v-model="selectAllChk" @change="checkSelectAll"></th>
                            <th scope="col" @click="getLabOrderBy('patient_lab.ordered_datetime', $event)"
                                class="active">Requisition Date <img src="/images/down-arrow-new.svg" class="img-fluid">
                            </th>
                            <th scope="col" @click="getLabOrderBy('patient_name', $event)">Demographic <img
                                    src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" @click="getLabOrderBy('patient_lab.lab_name', $event)">Service <img
                                    src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" @click="getLabOrderBy('patient_lab.abnormality', $event)">Abnormality <img
                                    src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" @click="getLabOrderBy('patient_lab.status', $event)">Latest Status <img
                                    src="/images/down-arrow-new.svg" class="img-fluid"></th>
                            <th scope="col" class="hover_unset cursor-auto"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in this.allLabList" :key="index"
                            :class="{ 'active': (selectedItems.includes(item.id)) }">
                            <td><input class="form-check-input" type="checkbox" :checked="selectedItems.includes(item.id)"
                                    @change="checkSelect(item.id)"></td>
                            <td>{{ $filters.inputDateTime(item.ordered_datetime, 'YYYY-MM-DD') }}</td>
                            <td>{{ item.patient_name }}</td>
                            <td>{{ item.lab_name }}</td>
                            <!-- <td>
                                <div class="line_dropdown labs-table-drop-select">
                                    <CustomDropDown :options="services" :initialValue="item.lab_name"
                                        v-model="allLabList.services" @item-selected="handleServicesField"
                                        @item-change="updateLabFieldVal($event, 'lab_name', item.id, item.patient_id)"
                                        fieldName="sub_type_option" divClass="form-label"></CustomDropDown>
                                </div>
                            </td> -->
                            <td>{{ item.abnormality }}</td>
                            <!-- <td>
                                <div class="line_dropdown labs-table-drop-select">
                                    <CustomDropDown :options="flag" :initialValue="item.abnormality"
                                        v-model="allLabList.abnormality" @item-selected="handleFlagTypeField"
                                        @item-change="updateLabFieldVal($event, 'abnormality', item.id, item.patient_id)"
                                        fieldName="abnormality_options" divClass="form-label"></CustomDropDown>
                                </div>
                            </td> -->
                            <td>{{ (item.tracing_role) ? item.tracing_role : item.status }}</td>
                            <td><span class="popup_link" @click="viewlogs(item.id, item.patient_id)"> View Logs</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="modal fade" id="add-viewlogs-model" tabindex="-1" style="display: none;" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered modal-xl">
                <div class="modal-content">
                    <div class="modal-body">
                        <button type="button" class="btn-close popup_close" data-bs-dismiss="modal"
                            aria-label="Close" @click="closeViewLogs"></button>
                        <div class="box">
                            <h2 class="popup_title">Tracing History</h2>
                            <div class="table-responsive specialist-list tracing-history-table">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Date</th>
                                            <th scope="col">Service</th>
                                            <th scope="col">Abnormality</th>
                                            <th scope="col">Tracing Status Role</th>
                                            <th scope="col" class="hover_unset"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in this.logList" :key="index">
                                            <td v-if="item.observation_datetime">{{ $filters.inputDateTime(item.observation_datetime, 'YYYY-MM-DD') }}</td>
                                            <td v-else>{{ $filters.inputDateTime(item.updated_datetime, 'YYYY-MM-DD') }}</td>
                                            <td>{{ item.lab_name }}</td>
                                            <td>{{ item.abnormality }}</td>
                                            <td>{{ item.tracing_role }}</td>
                                            <td><span class="popup_link" @click="viewLogDocument(item.id, item.tracing_role, item.patient_id, item.unique_id)"> {{ item.tracing_role === 'Created' ? 'View Request' : 'View File' }}</span></td>
                                        </tr>
                                        <!-- <tr v-if="this.logList && this.logList.length && this.logList[this.logList.length - 1].file_path">
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td><span class="popup_link" @click="viewPdf"> View PDF</span></td>
                                        </tr> -->
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="add-exportconsultation-model" tabindex="-1" style="display: none;" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
		<div class="modal-dialog modal-dialog-centered modal-xl">
			<div class="modal-content">
				<div class="modal-body">					
                        <button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close"></button>
                    <div class="box">
                        <ul class="export_tabs">
                            <li :class="{ active: isFaxFormVisible }" @click="changeExportOpt('fax')">Fax</li>
                            <li :class="{ active: isEmailFormVisible }" @click="changeExportOpt('email')">Email</li>
                            <li :class="{ active: isMailFormVisible }" @click="changeExportOpt('mail')">Mail</li>
                        </ul>
                        <section class="con_detl_sec">
                            <div class="fax_menu" v-if="isFaxFormVisible">
                                <div class="row align-items-center">
                                    <label for="text" class="col-sm-4 col-form-label right-bor py-0 fs-18">Specialist(s)</label>
                                    <div class="col-sm-8 position-relative selact_dropdown cunlt_specialist_option">
                                        <selectCheckBox :dataSource="specialist_option" :labelName="tagSpecialistLabel" @clicked="selectedSpecialist" :displayDiv="false" :isUnselectAll="true" unselectLabel="None" :isShowDoctorIcon="false"></selectCheckBox>
                                    </div>
                                 </div>   

                                <div class="or_line"><span>OR</span></div>

                                <!-- disable-fax -->
                                <div class="custom-form">
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="faxForm.fax_number" @input="handleFaxInput" @keydown="handleKeyDown" @paste="handlePaste" :maxlength="this.maxLength">
                                        <span class="multi-rc-fax-nmber white-space-nowrap d-inline-block me-2 mt-2" v-for="(phone, index) in this.faxForm.phoneNumbers" :key="index" style="display: flex; align-items: center;">
                                            {{ phone }}
                                            <img src="/images/close-icon.svg" @click="removeSelectedFax(phone)" class="cursor-pointer ml-10" style="margin-left: calc(var(--scale-ratio) * 10px); height: calc(var(--scale-ratio) * 10px);">
                                        </span>
                                        <label class="form-label" for="typeunique_idX-2">Recipient Fax Number</label>
                                        <ValidationErrorMessageList :errors="v$.faxForm.phoneNumbers.$errors" />
                                    </div>
                                </div>

                            </div>
                            <div class="email_menu" v-if="isEmailFormVisible">
                                <div class="custom-form">
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="emailForm.email_id">
                                        <label class="form-label" for="typeunique_idX-2">Recipient Email</label>
                                        <ValidationErrorMessageList :errors="v$.emailForm.email_id.$errors" />
                                    </div>
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="emailForm.email_header">
                                        <label class="form-label" for="typeunique_idX-2">Email Header</label>
                                        <ValidationErrorMessageList :errors="v$.emailForm.email_header.$errors" />
                                    </div>
                                </div>

                            </div>
                            <div class="mail_menu" v-if="isMailFormVisible">
                                <div class="custom-form">
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.name">
                                        <label class="form-label" for="typeunique_idX-2">Recipient Name</label>
                                        <ValidationErrorMessageList :errors="v$.mailForm.name.$errors" />
                                    </div>
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.address">
                                        <label class="form-label" for="typeunique_idX-2">Recipient Address</label>
                                        <ValidationErrorMessageList :errors="v$.mailForm.address.$errors" />
                                    </div>
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.city">
                                        <label class="form-label" for="typeunique_idX-2">Recipient City</label>
                                        <ValidationErrorMessageList :errors="v$.mailForm.city.$errors" />
                                    </div>
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.state">
                                        <label class="form-label" for="typeunique_idX-2">Recipient Province</label>
                                        <ValidationErrorMessageList :errors="v$.mailForm.state.$errors" />
                                    </div>
                                    <div class="input_box">
                                        <input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" v-model="mailForm.zip">
                                        <label class="form-label" for="typeunique_idX-2">Recipient Postal Code</label>
                                        <ValidationErrorMessageList :errors="v$.mailForm.zip.$errors" />
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div class="btn_part text-center">
                            <button class="comman_brdr_btn big_btn mx36" @click="closeExportModel"> Cancel </button>
                            <button class="comman_btn big_btn mx36" @click="exportSubmit">Confirm  <img src="images/loader.gif" v-if="faxLoader" style="width: calc(var(--scale-ratio) * 18px);" /></button>
                        </div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
import sidebar from './sidebar.vue';
// import CustomDropDown from '../../base/formFields/CustomDropDown.vue';
import CustomFilter from '../../base/CustomFilter.vue';
import axios from "@/scripts/axios.js";
import useVuelidate from "@vuelidate/core";
import ValidationErrorMessageList from '../../base/ValidationErrorMessageList.vue';
import selectCheckBox from "@/components/base/selectCheckBox.vue";
import _ from 'lodash';

export default {
    setup: () => {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            selectChk: [],
            selectedItems: [],
            allItems: [],
            selectAllChk: false,

            updateRowData: {
                id: '',
                field_name: '',
                field_val: '',
                patient_id: this.$route.params.patient_id,
            },

            allLabList: [],
            labFormData: {
                id: '',
                patient_id: this.$route.params.patient_id,
                lab_search: '',
                advanceFilter: {},
                sort_order: '',
                sort_by: '',
            },
            lab_search: '',
            filterList:[],
            isShowCustomFilter: false,
            services:[
                { value: "Blood Work", title: "Blood Work" },
                { value: "Microbiology", title: "Microbiology" },
                { value: "Urine", title: "Urine" },
                { value: "Others", title: "Others" },
            ],
            flag: [
                { value: "Unknown", title: "Unknown" },
                { value: "Normal", title: "Normal" },
                { value: "Abnormal", title: "Abnormal" },
            ],
            status:[
                { value: "Requisition Sent", title: "Requisition Sent" },
                { value: "Results Received", title: "Results Received" },
                { value: "Other", title: "Other" },
            ],
            filterOptions:[
                { value: "received_timerange", title: "Requisition Date" },
                { value: "demographic", title: "Demographic" },
                { value: "service_type", title: "Service" },
                { value: "abnormality_type", title: "Abnormality" },
                { value: "status", title: "Latest Status" },
            ],
            logList: [],
            viewLabId: '',
            isFaxFormVisible:true,
            isEmailFormVisible:false,
            isMailFormVisible:false,
            specialist_option:[],
            tagSpecialistLabel: "",
            faxLoader:false,
        }
    },
    components: {
        sidebar,
        // CustomDropDown,
        CustomFilter,
        ValidationErrorMessageList,
        selectCheckBox
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
        this.getSpecialistList();
        this.getAllLabList();
    },
    created(){
        const queryId = this.$route.query.id;
        if (queryId && Number(queryId) > 0) {
            setTimeout(() => {
                this.viewlogs(Number(queryId), this.$route.params.patient_id)
            }, 800);
        }

        const exportId = this.$route.query.exportId;
        if (exportId && Number(exportId) > 0) {
            this.selectedItems = [Number(exportId)]
            setTimeout(() => {
                this.exportconsults()
            }, 800);
        }

        this.debounceFormatFaxNumber = _.debounce(this.formatFaxNumber, 300); // Wait 300ms after typing stops
    },
    methods:{
        viewLogDocument(documentId, tracingRole, patient_id,uniqueId){
            if (tracingRole === 'Created') {
                const url = this.$router.resolve({
                    name: 'editModule', params: { patient_id: patient_id, id:uniqueId, module:this.$route.meta.module}
                }).href;

                window.open(url, '_blank');
            } else {
                const url = this.$router.resolve({
                    name: 'PatientEChartFilesEdit', params: { id:documentId,patient_id:patient_id}
                }).href;

                window.open(url, '_blank');
            }
        },
        viewPdf(){
            window.open(this.documentUrl(this.logList[this.logList.length - 1].file_path), '_blank');
        },
        closeViewLogs(){
            this.logList = [];
            this.viewLabId = '';
            const queryId = this.$route.query.id;
            if (queryId && Number(queryId) > 0) {
                this.$router.push({ name: 'patientLabsList', params: {patient_id: this.$route.params.patient_id} });
            }
        },
        viewlogs(LabId, patient_id){
            this.viewLabId = LabId;
            window.$("#add-viewlogs-model").modal("show");
            this.$store.state.loader = true;
            axios.post("patient/lab/view-logs", { patient_id: patient_id, id: this.viewLabId })
            .then((response) => {
                this.logList = response.data.data;
                this.$store.state.loader = false;
            });
        }, 
        documentUrl(documentPath) {
            // Assuming your documents are stored in the 'public' directory
            return `${process.env.VUE_APP_STORAGE_URL}/document/view/${documentPath}`;
        },
        checkSelect(id){
            // Handle individual item selection/deselection
            const index = this.selectedItems.indexOf(id); // Find index of id in selectedItems
            if (index !== -1) {
                this.selectedItems.splice(index, 1); // Remove id from selectedItems
            } else {
                this.selectedItems.push(id)
            }

            // Update selectAllChk based on selectedItems length
            this.selectAllChk = this.selectedItems.length === this.allItems.length;
        },
        checkSelectAll(){
            if (this.selectAllChk) {
                this.selectedItems = [...this.allItems];
            } else {
                this.selectedItems = []
            }
        },
        updateLabFieldVal(value, field, id, patient_id)
        {
            this.updateRowData.id = id;
            this.updateRowData.field_name = field,
            this.updateRowData.field_val = value;
            this.updateRowData.patient_id = patient_id;

            axios.post('patient/lab/row-update', this.updateRowData )
                .then((response) => {
                    if (response.status == 200) {
                        this.getAllLabList();
                        this.$filters.moshaToast(response.data.message, "success");
                    } else {
                        this.$filters.moshaToast(response.data.message, "error");
                    }
                }).catch(error => {
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        console.log(Object.values(error.response.data.data).join(', '))
                    } else if(error.response.status === 402){
						window.location.href = error.response.data.redirect_url;
					} else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });
        },
        getLabOrderBy(orderColumnName, event){
            event.stopPropagation(); 

            const th = event.target;
            this.labFormData.sort_by = orderColumnName;
            if (th.classList.contains('active')) {
                th.classList.remove('active');
                this.labFormData.sort_order = 'asc';
            } else {
                th.classList.add('active');
                this.labFormData.sort_order = 'desc';
            }

            this.getAllLabList();
        },
        handleCustomField(values){
            this.filterList = values
            let advanceFilter={}
			values.forEach(item => {
                let value = item.value
                if (item.value == 'Not Set') {
                    value = ''
                }
                advanceFilter[item.field] = value
            });
            this.labFormData.advanceFilter = advanceFilter
        },
        addFilter(){
            this.isShowCustomFilter = !this.isShowCustomFilter;
        },
        patientLabSearch() {
            if(this.lab_search.length > 2)
            {
                this.labFormData.lab_search = this.lab_search;
                this.getAllLabList();
            }
            if(this.lab_search.length == 0)
            {
                this.labFormData.lab_search = '';
                this.getAllLabList();
            }
        },
        handleServicesField(values) {
            console.log('handleSubTypeField:- ', values);
        },
        handleFlagTypeField(values) {
            console.log('handleFlagTypeField:- ', values);
        },
        getAllLabList() {
            this.$store.state.loader = true;
            axios.post('patient/lab/list', this.labFormData )
                .then((response) => {
                    this.$store.state.loader = false;
                    if (response.status == 200) {
                        this.selectAllChk = false
                        this.selectedItems = []
                        this.allItems = []

                        this.allLabList = response.data.data;
                        this.allLabList.forEach(item => {
                            this.allItems.push(item.id)
                        });
                    } else {
                        this.$filters.moshaToast(response.data.message, "error");
                    }
                }).catch(error => {
                    this.$store.state.loader = false;
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        console.log(Object.values(error.response.data.data).join(', '))
                    } else if(error.response.status === 402){
						window.location.href = error.response.data.redirect_url;
					} else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });
        },
        selectTemplate(){
            this.$router.push(`/patient/e-chart/${this.$route.params.patient_id}/template/${this.$route.meta.module}/list`);
        },
        exportconsults(){
            this.v$['faxForm'].$reset();
            this.v$['emailForm'].$reset();
            this.v$['mailForm'].$reset();
            Object.assign(
				this.$store.state.lab.faxForm,
				this.$store.state.lab.defaultexportfrm
			);
            Object.assign(
				this.$store.state.lab.emailForm,
				this.$store.state.lab.defaultexportfrm
			);
            Object.assign(
				this.$store.state.lab.mailForm,
				this.$store.state.lab.defaultexportfrm
			);
            this.tagSpecialistLabel = ''
            this.specialist_option.map((item) => {
                item.checked = false;
            });
            this.isFaxFormVisible=true
            this.isEmailFormVisible=false
            this.isMailFormVisible=false

            window.$("#add-exportconsultation-model").modal("show");
        },
        changeExportOpt(type){
            this.isFaxFormVisible=false
            this.isEmailFormVisible=false
            this.isMailFormVisible=false

            if (type == 'fax') {
                this.isFaxFormVisible=true
            } else if (type == 'email') {
                this.isEmailFormVisible=true
            } else if (type == 'mail') {
                this.isMailFormVisible=true
            }
        },
        closeExportModel(){
            this.selectedItems = []
            this.selectAllChk = false

            window.$("#add-exportconsultation-model").modal("hide");
        },
        exportSubmit(){
            let form = null
            let formName = 'faxForm'
            if (this.isFaxFormVisible) {
                form = { ...this.faxForm };
                form.type = "fax"
                formName = 'faxForm';
                form.fax_number = this.faxForm['phoneNumbers'].join(', ');
            } else if(this.isEmailFormVisible){
                form = this.emailForm
                form.type = "email"
                formName = 'emailForm';
            } else if (this.isMailFormVisible){
                form = this.mailForm
                form.type = "mail"
                formName = 'mailForm';
            }
            form.ids = this.selectedItems
            this.v$[formName].$validate();

            if (!this.v$[formName].$error) {
               
                this.faxLoader = true
                this.faxForm.fax_number = '';

                let url = "patient/lab/export"
                axios.post(url, form)
                    .then((response) => {
                        if (response.status == 200) {
                            this.faxLoader = false;

                            if (formName == 'faxForm') {
                                if (response.data.data && response.data.data.redirectUrl) {
                                    window.open(response.data.data.redirectUrl, "_blank");
                                    return true
                                }
                                let allSuccess = false
                                for (const detail of response.data.data) {
                                    if (detail.status == 'success'){
                                        allSuccess = true
                                        this.$filters.moshaToast(detail.msg, "success");
                                    } else {
                                        this.$filters.moshaToast(detail.msg, "error");
                                    }
                                }
                                if (allSuccess) {
                                    this.selectedItems = []
                                    this.selectAllChk = false
                                    this.faxForm.phoneNumbers = [];
                                    window.$("#add-exportconsultation-model").modal("hide");
                                }

                                const exportId = this.$route.query.exportId;
                                if (exportId && Number(exportId) > 0) {
                                    this.$router.push({ name: 'patientLabsList', params: {patient_id: this.$route.params.patient_id} });
                                }
                            } else {
                                this.selectedItems = []
                                this.selectAllChk = false
                                this.$filters.moshaToast(response.data.message, "success");
                                window.$("#add-exportconsultation-model").modal("hide");
                            }
                        } else {
                            this.$filters.moshaToast(response.data.message, "error");
                        }
                    }).catch(error => {
                        this.faxLoader = false;
                                this.$filters.moshaToast(error.response.data.message, "error");
                            });
            } else {
                this.faxLoader = false;
                for (const [key] of Object.entries(this.v$[formName])) {
					if (this.v$[formName][key].$errors && this.v$[formName][key].$errors.length > 0) {
						const myEl = document.getElementById(key);
						if (myEl) {
							this.$smoothScroll({
								scrollTo: myEl,
							});
							break;
						}
					}
				}
            }
        },
        handleItemSelected(values){
            this.faxForm.specialist_id = values.item.id
        },
        getSpecialistList(){
            axios.post("custom-specialists/list", {})
                .then((response) => {
                    if (response.status == 200) {
                        const details = response.data.data;
                        details.sort((a, b) => {
                            return (b.is_preferred_specialist == true) - (a.is_preferred_specialist == true);
                        });
                        const specialistTempData = details.map((item) => {
                            let name = item.name;
                            if (item.is_preferred_specialist) {
                                name += "<p class='preferred-label float-end mb-0 mt-10'>(Preferred)</p>"
                            }
                            if (item.address){
                                name += "<p class='address-line mb-0'>"+item.address+"</p>"
                            }
                            return { id: item.id, name: item.name, checked: false, displayLabel: name };
                        });
                        this.specialist_option = specialistTempData;
                    } else {
                        this.$filters.moshaToast(response.data.message, "error");
                    }
                }).catch(error => {
                    this.loader = false;
                    console.log(error)
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                    } else if(error.response.status === 402){
						window.location.href = error.response.data.redirect_url;
					} else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });
        },
        handleClickOutside(event) {
            if (this.isShowCustomFilter && !event.target.closest('.comman_btn') && !event.target.closest('.search_drop_box') && !event.target.closest('.search') && event.target.closest('.a_header')) {
                this.getAllLabList();
                this.isShowCustomFilter = false;
            }

        },
        selectedSpecialist(value){
                this.specialist_option = value;
				const specialist_list = this.specialist_option.map((item) => {
					return (item.checked == true) ? item.id : null;
				});
				this.faxForm.specialist_id = specialist_list.filter(n => n);
				if (this.faxForm.specialist_id.length > 0) {
                    if (this.faxForm.specialist_id.length == 1) {
                        this.tagSpecialistLabel = `${this.faxForm.specialist_id.length} Specialist Selected`;
                    } else {
                        this.tagSpecialistLabel = `${this.faxForm.specialist_id.length} Specialist(s) Selected`;
                    }
				} else {
					this.tagSpecialistLabel = "";
				}
			},
        handleFaxInput() {
            if (this.faxForm.phoneNumbers.length >= 5) {
                // If the limit is exceeded, use the debounced version
                this.debounceFormatFaxNumber();
            } else {
                // Otherwise, call the method directly
                this.formatFaxNumber();
            }
        },
        handlePaste(event) {
            // Get the pasted content
            const pasteData = (event.clipboardData || window.clipboardData).getData("text");
            // Allow only digits and "-"
            let sanitizedData = pasteData.replace(/[^0-9-]/g, "");

            // If the sanitized data is empty, reject the paste
            if (!sanitizedData) {
                console.log("Invalid input: Only numbers and '-' are allowed.");
                event.preventDefault();
                return;
            }

            // Remove "-" from the sanitized data
            sanitizedData = sanitizedData.replace(/-/g, "");

            // Determine max length based on presence of "-"
            const currentLength = this.faxForm.fax_number ? this.faxForm.fax_number.length : 0;
            const allowedLength = this.maxLength - currentLength;
            // Trim pasted content to fit the remaining allowed length
            const trimmedData = sanitizedData.slice(0, allowedLength);

            // Update the input value
            this.faxForm.fax_number = (this.faxForm.fax_number || "") + trimmedData;
        },
        handleKeyDown(event) {
            if (event.key == 'Backspace' && this.faxForm.fax_number) {
                this.faxForm.fax_number = this.faxForm.fax_number.slice(0, -1);
                event.preventDefault();
            }
            if (this.faxForm.fax_number && this.faxForm.fax_number.length >= this.maxLength) {
                event.preventDefault();
            }

            const allowedKeys = [
				"Backspace",
				"ArrowLeft",
				"ArrowRight",
				"Tab",
				"-",
			];
			const isAllowedKey = allowedKeys.includes(event.key);
			const isDigit = event.key >= "0" && event.key <= "9";
            const isPasteShortcut = (event.ctrlKey || event.metaKey) && event.key.toLowerCase() === "v";
			if (!isDigit && !isAllowedKey && !isPasteShortcut) {
				event.preventDefault();
			}

        },
        formatFaxNumber() {
            const { fax_number, phoneNumbers } = this.faxForm;

            if (this.faxForm.phoneNumbers.length >= 5 && this.faxForm.fax_number) {
                this.$filters.moshaToast("You cannot add more than five recipient fax numbers for sending.", "error");
                this.faxForm.fax_number = '';
                return;
            }

            // Ensure no trailing commas and trim input
            if (fax_number.endsWith(',')) {
                this.faxForm.fax_number = fax_number.slice(0, -1).trim();
                return;
            }

            // Format the fax numbers
            const result = this.formatFaxWithCheckDuplicateNumber(fax_number, phoneNumbers);

            // Show the toast message, if try to add duplicate fax number
            if (result.hasDuplicates) {
                this.$filters.moshaToast("You cannot add recipient duplicate fax numbers.", "error");
            }

            // Update the model
            this.faxForm.fax_number = result.faxNumber;
            this.faxForm.phoneNumbers = result.phoneNumbers;
        },
        formatFaxWithCheckDuplicateNumber(faxNumber, phoneNumbers) {
            let faxRegex = /^(1[ .-]?)?(\d{3}|\(\d{3}\))[ .-]?\d{3}[ .-]?\d{4}$/; // Pattern for fax numbers
            faxNumber = faxNumber.trim(); // Trim spaces

            let faxNumbersArray = faxNumber.split(',').map(fax => fax.trim()); // Split input by comma

            // Check for invalid numbers if the last character is a comma
            if (faxNumber.endsWith(',')) {
                const lastFaxNumber = faxNumbersArray[faxNumbersArray.length - 2];
                if (lastFaxNumber && lastFaxNumber.length === 0 || !faxRegex.test(lastFaxNumber)) {
                    faxNumbersArray.splice(faxNumbersArray.length - 2, 1); // Remove invalid number
                    faxNumber = faxNumbersArray.join(', ');
                    return { faxNumber, phoneNumbers };
                }
            }

            // Format and validate fax numbers
            const formattedFaxNumbers = faxNumbersArray.map(number => {
                const numericFax = number.replace(/\D/g, ""); // Remove non-numeric characters
                if (numericFax.length >= 3 && numericFax.length <= 6) {
                    return `${numericFax.slice(0, 3)}-${numericFax.slice(3)}`;
                } else if (numericFax.length > 6) {
                    return `${numericFax.slice(0, 3)}-${numericFax.slice(3, 6)}-${numericFax.slice(6, 10)}`;
                }
                return number; // Keep it as is if length < 3
            });

            let remainingFaxNumbers = [];
            let hasDuplicates = false;

            // Check for duplicates and validate numbers
            formattedFaxNumbers.forEach(formattedFax => {
                if (faxRegex.test(formattedFax)) {
                    if (phoneNumbers.includes(formattedFax)) {
                        hasDuplicates = true; // Detect duplicates
                    } else {
                        phoneNumbers.push(formattedFax); // Add valid number
                    }
                } else {
                    remainingFaxNumbers.push(formattedFax); // Store invalid numbers
                }
            });

            // Remove duplicates and sort numbers
            phoneNumbers = Array.from(new Set(phoneNumbers)).sort((a, b) => {
                const numA = a.replace(/\D/g, '');
                const numB = b.replace(/\D/g, '');
                return numB.localeCompare(numA);
            });

            // Join remaining invalid numbers back with commas
            faxNumber = remainingFaxNumbers.join(', ');

            return { faxNumber, phoneNumbers, hasDuplicates };
        },
        // handleKeyDown(event) {
        //     if (event.key == 'Backspace') {
		// 		this.faxForm.fax_number = this.faxForm.fax_number.slice(0, -1);
		// 		event.preventDefault();
        //     }
        // },
        // formatFaxNumber(){
        //     const { fax_number, phoneNumbers } = this.faxForm;

        //     // Check the length of phoneNumbers after formatting
        //     if (this.faxForm.phoneNumbers.length >= 5 && this.faxForm.fax_number) {
        //         this.faxForm.fax_number = ''; // Clear the fax_number
        //         this.$filters.moshaToast("You cannot add more than five recipient fax numbers for sending.", "error");
        //         return false; // Prevent further processing
        //     }

        //     // Call the globally defined formatFaxNumber
        //     const result = this.$filters.formatFaxNumber(fax_number, phoneNumbers);

        //     // Update the model based on the result
        //     this.faxForm.fax_number = result.faxNumber;
        //     this.faxForm.phoneNumbers = result.phoneNumbers;
        // },
        removeSelectedFax(numberToRemove){
            const updatedPhoneNumbers = this.faxForm.phoneNumbers.filter(phone => phone !== numberToRemove);
            this.faxForm.phoneNumbers = updatedPhoneNumbers
        }
    },
    computed: {
        faxForm(){
            return this.$store.state.lab.faxForm;
        },
        emailForm(){
            return this.$store.state.lab.emailForm;
        },
        mailForm(){
            return this.$store.state.lab.mailForm;
        },
        maxLength() {
            return (this.faxForm.fax_number && this.faxForm.fax_number.includes("-")) ? 12 : 10;
        },
    },
	validations() {
		return {
			faxForm: this.$store.state.lab.validationRules.faxForm,
			emailForm: this.$store.state.lab.validationRules.emailForm,
            mailForm: this.$store.state.lab.validationRules.mailForm,
		};

	},
}
</script>
